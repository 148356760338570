<template>
  <v-dialog
    v-model="dialogLocal"
    max-width="450px"
    persistent
  >
    <v-card>
      <v-card-title
        class="headline-custom"
        style="font-size: 1.3rem !important; hyphens: auto"
      >
        Nova linha
      </v-card-title>
      <v-card-text style="font-size: 15px !important">
        <v-row class="align-center justify-left px-4">
          <v-col
            cols="12"
            v-for="(campo, index) in campos"
            :key="index"
          >
            <div class="mt-4 text-h6">
              {{ campo.column }}
            </div>
            <div v-if="['smallint', 'bigint', 'integer', 'inteiro'].includes(campo.type)">
              <v-text-field
                v-model="linha[campo.column]"
                type="number"
                :step="1"
                autofocus
              />
            </div>
            <div v-else-if="['decimal', 'numeric'].includes(campo.type)">
              <v-text-field
                v-model="linha[campo.column]"
                type="number"
                :step="0.01"
                autofocus
              />
            </div>
            <div v-else-if="campo.type === 'data'">
              <input-date
                :label="null"
                :selectedDate="linha[campo.column]"
                :rules="{ required: true, min: 10 }"
                @dataSelected="(data) => (linha[campo.column] = data)"
              />
            </div>
            <div v-else>
              <v-text-field
                v-model="linha[campo.column]"
                single-line
                counter
                autofocus
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="darken-1"
          text
          @click="dialogLocal = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary darken-1"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AtualizacoesTabelasService from '@/services/AtualizacoesTabelasService';

export default {
  mixins: [],
  components: {
    InputDate: () => import('@/components/general/InputDate')
  },

  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    campos: {
      type: Array,
      required: true
    },
    tabela: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      linha: {}
    };
  },

  computed: {
    dialogLocal: {
      get() {
        return this.dialog;
      },
      set(newValue) {
        this.$emit('update:dialog', newValue);
      }
    }
  },

  watch: {
    campos() {
      this.initiateCampos();
    },
    dialog(newValue) {
      if (newValue) {
        this.initiateCampos();
      }
    }
  },

  methods: {
    initiateCampos() {
      for (const campo of this.campos) {
        this.$set(this.linha, campo.column, '');
      }
    },
    save() {
      AtualizacoesTabelasService.criar(this.tabela, this.linha)
        .then(({ data }) => {
          this.dialogLocal = false;
          this.$toast.success(
            `Nova linha #${data.id} criada com sucesso.`,
            '',
            { position: 'topRight' }
          );
          this.$emit('created', data);
        })
        .catch((error) => {
          this.$toast.error(
            `Ocorreu um erro ao criar nova linha`,
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    }
  }
};
</script>
